<template>
  <el-dialog
    :z-index="1020"
    custom-class="order-summary"
    :visible="true"
    :rounded="true"
    :show-close="false"
    :append-to-body="false"
    :close-on-click-modal="false"
    destroy-on-close
    @close="$emit('on-close')"
  >
    <template #title>
      <div class="d-flex w-100 justify-content-between">
        <h2>{{ $t('orderProcessCart.orderSummary.title') }}</h2>
        <Button type="icon" class="p-0" @click="$emit('on-close')">
          <CloseIcon />
        </Button>
      </div>
    </template>

    <div v-loading="loading" class="d-flex flex-column gap-5 w-100">
      <el-alert
        v-if="hasAlertForProduct"
        :title="$t('orderProcessCart.orderSummary.hasAlertForProduct')"
        type="warning"
        show-icon
        :closable="false"
        class="text-break"
      />
      <el-alert
        v-if="amountToReachForOrder > 0"
        :title="
          $t('orderProcessCart.orderSummary.amountToReachForOrder', {
            amountToReachForOrder: formatMoney(amountToReachForOrder),
          })
        "
        type="warning"
        show-icon
        :closable="false"
        class="text-break"
      />
      <div class="d-flex flex-column gap-4 w-100">
        <OrderSummaryRow
          :title="$t('orderProcessCart.orderSummary.orderedBy')"
          :content="tenantName"
          :small-content="tenantAddress ?? MISSING_DATA_TEXT"
        />
        <OrderSummaryRow
          :title="$t('orderProcessCart.orderSummary.orderedFrom')"
          :content="supplierName"
          :small-content="supplierAddress ?? MISSING_DATA_TEXT"
        />
        <OrderSummaryRow :title="$t('orderProcessCart.orderSummary.orderNumber')" :content="orderNumber?.toString()" />
        <OrderSummaryRow :title="$t('orderProcessCart.orderSummary.orderArrivalTime')">
          <OrderArrivalTimePopover
            :order-id="orderId"
            :arrives-at="arrivesAt ?? closestArrivalDate"
            :updated-at="updatedAt"
            :show-for-delivery-text="false"
            :supplier-order-terms="supplierOrderTerms"
            @on-arrives-at-updated="onArrivesAtUpdated"
            @on-cut-off-date-change="onCutOffDateChange"
          />
        </OrderSummaryRow>
        <OrderSummaryRow
          :title="$t('orderProcessCart.orderSummary.orderSentBy')"
          :content="`${user.profile.firstName} ${user.profile.lastName}`"
        />
        <OrderSummaryRow :title="$t('orderProcessCart.orderSummary.orderSentTo')">
          <div class="w-50">
            <div v-for="(contact, index) in contactInfos" :key="index" class="d-flex gap-2 align-items-center">
              <component :is="getContactIconComponent(contact.method)" :size="16" fill="#1F284D" />
              <TruncatedText class="w-100">{{ getContactText(contact) }}</TruncatedText>
            </div>
          </div>
        </OrderSummaryRow>
      </div>
      <hr />
      <div v-if="hasAllPrices" class="d-flex flex-column gap-1 w-100">
        <div class="d-flex w-100 justify-content-between">
          <p>{{ $t('orderProcessCart.orderSummary.products') }}</p>
          <p>{{ products.length }}</p>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex gap-1 align-items-center">
            <p class="fw-bold fs-6">{{ $t('orderProcessCart.orderSummary.estimatedTotal') }}</p>
            <el-tooltip :content="$t('orderProcessCart.orderSummary.estimatedTotalTooltip')" placement="top">
              <SimulationQuestionMarkIcon />
            </el-tooltip>
          </div>
          <p class="fw-bold fs-6">{{ formatMoney(total) }}</p>
        </div>
      </div>
      <div v-else class="d-flex flex-column gap-1 w-100">
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex gap-1 align-items-center">
            <p>{{ $t('orderProcessCart.orderSummary.productsWhichPriceIsKnown') }}</p>
            <el-tooltip :content="$t('orderProcessCart.orderSummary.productsWhichPriceIsKnownTooltip')" placement="top">
              <SimulationQuestionMarkIcon :size="12" />
            </el-tooltip>
          </div>
          <p>{{ amountOfProductsThatWeHavePriceFor }}</p>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <p class="fw-bold fs-6">{{ $t('orderProcessCart.orderSummary.estimatedTotal') }}</p>
          <p class="fw-bold fs-6">{{ formatMoney(total) }}</p>
        </div>
        <hr class="dotted" />
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex gap-1 align-items-center">
            <p>{{ $t('orderProcessCart.orderSummary.productsWhichPriceIsUnknown') }}</p>
            <el-tooltip
              :content="$t('orderProcessCart.orderSummary.productsWhichPriceIsUnknownTooltip')"
              placement="top"
            >
              <SimulationQuestionMarkIcon :size="12" />
            </el-tooltip>
          </div>
          <p>{{ amountOfProductsThatWeDontHavePriceFor }}</p>
        </div>
      </div>
      <hr />
      <div class="order-notes d-flex flex-column gap-2">
        <p class="fw-bold">{{ $t('orderProcessCart.orderSummary.notesTitle') }}</p>
        <el-input
          v-model="orderNote"
          type="textarea"
          :autosize="{ minRows: 2 }"
          :placeholder="$t('orderProcessCart.orderSummary.notesPlaceholder')"
        />
      </div>
    </div>

    <template #footer>
      <div class="d-flex justify-content-end">
        <Button type="secondary" @click="$emit('on-close')">{{ $t('commons.cancel') }}</Button>
        <Button v-loading="patchLoading" :disabled="isSubmitDisabled" type="primary" @click="sendOrder">
          <div class="d-flex gap-2 align-items-center">
            {{ $t('orderProcessCart.orderSummary.sendOrder') }}
            <SendIcon :class="$direction === 'ltr' ? 'flip' : ''" />
          </div>
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';

import { Button, TruncatedText } from '@/modules/core';
import {
  EmailIcon,
  WhatsAppIcon,
  SimulationQuestionMarkIcon,
  SendIcon,
  CloseIcon,
  IntegrationIcon,
} from '@/assets/icons';
import { useSupplierForOrderSummary } from '../compositions/useSupplierForOrderSummary';
import { usePatchOrderProcess } from '../compositions/usePatchOrderProcess';

import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import {
  formatCurrencyOrDash,
  formatDayAndTimeWithDate,
  returnDashInsteadOfNull,
} from '@/modules/purchase-management/purchaseManagementFormatters';
import { useCurrency } from '@/locale/useCurrency';
import { useTenancy, useUser } from '@/modules/auth';
import OrderSummaryRow from './OrderSummaryRow.vue';
import OrderArrivalTimePopover from './OrderArrivalTimePopover.vue';
import { useAnalytics } from '../../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';

export default {
  components: {
    Button,
    EmailIcon,
    WhatsAppIcon,
    OrderArrivalTimePopover,
    SimulationQuestionMarkIcon,
    SendIcon,
    CloseIcon,
    OrderSummaryRow,
    TruncatedText,
    IntegrationIcon,
  },
  props: {
    orderId: { type: String, default: null },
    orderNumber: { type: Number, default: null },
    closestArrivalDate: { type: Date, default: null },
    products: { type: Array, required: true },
    supplierOrderTerms: { type: Array, required: true },
    supplierId: { type: String, required: true },
    existingOrderNote: { type: String, default: null },
    arrivesAt: { type: Date, default: null },
    updatedAt: { type: Number, default: null },
  },
  emits: ['on-close', 'on-sent-order', 'on-arrives-at-updated'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const orderNote = ref(props.existingOrderNote);
    const { orderId } = root.$route.params;
    const { currencyFormat } = useCurrency();
    const { currentTenant } = useTenancy();
    const { user } = useUser();
    const isSubmitDisabled = ref(false);
    const { business, loading } = useSupplierForOrderSummary(computed(() => ({ businessId: props.supplierId })));
    const { patchOrderProcess, loading: patchLoading, onDone: onPatchDone } = usePatchOrderProcess();
    const { logEvent } = useAnalytics();
    const isOrderSent = ref(false);

    const products = computed(() => props.products);

    const amountOfProductsThatWeHavePriceFor = computed(() => products.value.filter(({ total }) => total).length);
    const amountOfProductsThatWeDontHavePriceFor = computed(() => products.value.filter(({ total }) => !total).length);

    const hasAllPrices = computed(() => amountOfProductsThatWeHavePriceFor.value === products.value.length);
    const relevantOrderTerm = computed(() =>
      props.supplierOrderTerms.length > 0 ? props.supplierOrderTerms[0] : undefined
    );

    const contactInfos = computed(() => {
      return relevantOrderTerm.value ? relevantOrderTerm.value.contactInfo : [];
    });

    const minimumOrderAmount = computed(() => relevantOrderTerm.value.minimumOrderAmount);

    const total = computed(() =>
      products.value.reduce((sum, product) => {
        const totalNumeric = product.total ? Number(product.total.replace(/\D/g, '')) / 100 : 0;
        return (sum += totalNumeric);
      }, 0)
    );

    const amountToReachForOrder = computed(() => {
      if (hasAllPrices.value) {
        return Number(minimumOrderAmount.value) - Number(total.value);
      }

      return null;
    });

    const tenantAddress = computed(() => currentTenant.value.contact.address);
    const tenantName = computed(() => currentTenant.value.name);

    const amount = computed(() => props.supplierOrderTerms.totalCount);
    const supplierName = computed(() => business.value.getBusinessById?.name ?? null);
    const supplierAddress = computed(() => business.value.getBusinessById?.contact?.address ?? null);

    const hasAlertForProduct = computed(() =>
      props.products.find(({ alertQuantity }) => alertQuantity) ? true : false
    );

    const logOrderNotes = () => {
      if (!isOrderSent.value && orderNote.value !== props.existingOrderNote) {
        logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.NOTE, { orderId, note: orderNote.value ?? '' });
      }
    };

    onMounted(() => {
      window.addEventListener('beforeunload', logOrderNotes);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', logOrderNotes);
      logOrderNotes();
    });

    const sendOrder = () => {
      const dispatches = contactInfos.value.map((contact) => ({
        channel: contact.method,
        address: contact.address,
      }));
      const patchPayload = {
        note: orderNote.value ?? '',
        dispatches,
      };

      if (!props.arrivesAt) {
        patchPayload.arrivesAt = props.closestArrivalDate;
      }

      logOrderNotes();

      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.COMPLETE, { orderId, note: orderNote.value ?? '' });

      patchOrderProcess({
        orderId,
        patchPayload: patchPayload,
        updatedAt: props.updatedAt,
      });

      isOrderSent.value = true;
    };

    onPatchDone(() => emit('on-sent-order'));

    const getContactIconComponent = (method) => {
      switch (method) {
        case 'email':
          return 'EmailIcon';
        case 'sms':
          return 'WhatsAppIcon';
        case 'integration':
          return 'IntegrationIcon';
        default:
          return null;
      }
    };

    const getContactText = (contact) => {
      if (contact.method === 'integration') {
        return root.$t('orderTerms.contactInfo.integration');
      }
      return contact.address;
    };

    const onArrivesAtUpdated = (result) => {
      emit('on-arrives-at-updated', result);
    };

    const onCutOffDateChange = (newCutOffTime) => {
      if (!newCutOffTime) {
        isSubmitDisabled.value = true;
      } else {
        isSubmitDisabled.value = false;
      }
    };

    return {
      MISSING_DATA_TEXT,
      user,
      total,
      amount,
      loading,
      orderNote,
      tenantName,
      supplierName,
      patchLoading,
      hasAllPrices,
      tenantAddress,
      isSubmitDisabled,
      supplierAddress,
      hasAlertForProduct,
      amountToReachForOrder,
      amountOfProductsThatWeHavePriceFor,
      amountOfProductsThatWeDontHavePriceFor,
      sendOrder,
      onArrivesAtUpdated,
      onCutOffDateChange,
      formatMoney: (money) => formatCurrencyOrDash(money, currencyFormat.value),
      formatDayAndTimeWithDate: (date) => returnDashInsteadOfNull(formatDayAndTimeWithDate(date)),
      contactInfos,
      getContactIconComponent,
      getContactText,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .el-dialog__body {
    padding-bottom: 1rem;
    color: $typography-primary;
  }
  .el-dialog__footer {
    padding-top: 1rem;
  }
  .order-notes {
    textarea {
      resize: none;
    }
  }
}
hr {
  margin: 0;
}
hr.dotted {
  border-top: 1px dotted;
  color: #e5e8ea;
}
.divider {
  height: 0.5px;
  border: 1px solid #d9dcde;
}
.flip {
  transform: rotate(180deg);
}
</style>
<style lang="scss">
.order-summary {
  width: 31.25rem;
  border-radius: 0.5rem;
}
</style>
